import { Component } from '@angular/core';
import { GridApi, GridReadyEvent, ICellRendererParams, IDatasource } from 'ag-grid-community';
import * as moment from 'moment';
import { ApiClientConstant, ApiConnector, RequestQueryPayload, Table } from 'api-client';
import { Broadcaster } from 'src/components/broadcaster';
import { WindowRefService } from '../../../services/window-ref-service';
import { ConnectionService } from '../../../services/connection-service';
import { AppConfig } from '../../app.config';
import { LocalStorage } from '../../../services/local-storage-service';
import { PendingCallsActionComponent } from './pending-calls-action/pending-calls-action.component';

@Component({ selector: 'calls-pending-calls', templateUrl: './pending-calls.html' })
export class PendingCallsComponent {
  gridApi: GridApi;
  dataSource: IDatasource;
  components: any;
  ui: any = {};
  columnDefs: any;
  count: number = 0;
  teams: Array<string> = [];
  typeOptions: Array<string> = [];
  operatorFilter: Array<string> = [];
  doctorFilter: Array<string> = [];
  languageFilter: Array<string> = [];
  teamFilter: Array<string> = [];
  typeFilter: Array<string> = [];
  resetTeamFilter: boolean = false;
  resetLanguageFilter: boolean = false;
  resetOperatorFilter: boolean = false;
  resetDoctorFilter: boolean = false;
  resetTypeFilter: boolean = false;
  autoRefresh: boolean = true;
  autoRefreshInterval: any;
  shuffleOrder: 'ascending' | 'descending' = 'ascending';
  searchKey: string;
  isRegimenConatainsTablet: any = {};
  regimenClass: any = {};
  userRoles: Array<string> = [];
  constructor(
    private windowRefService: WindowRefService,
    private connectionService: ConnectionService,
    public appConfig: AppConfig,
    private storage: LocalStorage,
    private broadcaster: Broadcaster) { }

  ngOnInit(): void {
    this.ui = { grid: { rowModelType: 'infinite', pageSize: 100 } };
    this.userRoles = this.storage.getJsonValue('userRoles') || [];
    const teams = Object.keys(this.appConfig.Shared.Role.Name)
      .map((each: string) => this.appConfig.Shared.Role.Name[each]);
    this.teams = this.userRoles.filter((each: any) => teams.includes(each));
    if (!this.teams.includes(this.appConfig.Shared.Role.Name.CHAT_SUPPORT)) {
      this.operatorFilter = [];
    }
    this.setUpGrid();
  }

  disableRowView(params: ICellRendererParams): boolean {
    if (this.userRoles.includes(this.appConfig.Shared.Role.Name.ADMIN_OPERATOR)
      || params.data.allocatedOperator?.objectId === this.connectionService.getCurrentUser().id) {
      return false;
    }
    return true;
  }

  setUpGrid(): void {
    this.dataSource = {
      rowCount: null,
      getRows: (params: any): void => {
        this.loadMore(params)
          .then((data: Array<any>) => {
            if (params.startRow === 0 && !data.length) this.gridApi.showNoRowsOverlay();
            else this.gridApi.hideOverlay();
            params.successCallback(data, data.length === this.ui.grid.pageSize ? -1 : params.startRow + data.length);
          });
      },
    };
    this.components = {
      loadingRenderer(params: any): any {
        if (params.value) return params.value;
        return '';
      },
    };
    this.columnDefs = [{
      headerName: 'Action',
      field: 'objectId',
      width: 150,
      cellRenderer: PendingCallsActionComponent,
      pinned: 'left',
    }, {
      headerName: 'Name',
      field: 'user.PatientName',
      cellRenderer: (params: any): any => {
        let eDiv;
        if (!params.data) {
          eDiv = this.windowRefService.nativeWindow.document.createElement('div');
          eDiv.innerHTML = 'Loading ...';
          return eDiv;
        }
        if (this.disableRowView(params)) {
          return '';
        }
        eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        let highlightedClass = '';
        if (this.isRegimenConatainsTablet[params.data.referenceId]?.isTablet) {
          highlightedClass = 'list-label list-label-bg-red';
        }
        eDiv.innerHTML = `<a href="/chat/${params.data.user?.objectId}" class='${highlightedClass}' target='_blank'>${params.value}</a>`;
        return eDiv;
      },
      width: 200,
    }, {
      headerName: 'Type',
      field: 'type',
      width: 100,
      cellRenderer: (params: any): any => {
        if (!params.data || this.disableRowView(params)) {
          return '';
        }
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
    }, {
      headerName: 'allocatedOperator',
      field: 'allocatedOperator.username',
      width: 100,
    }, {
      headerName: 'Amount Spent',
      field: 'totalAmountSpent',
      name: 'totalAmountSpent',
      width: 100,
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.data || this.disableRowView(params)) {
          return '';
        }
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
    }, {
      headerName: 'Time',
      field: 'requestTime',
      width: 120,
      sortable: true,
      cellRenderer: (params: any): any => {
        if (!params.value || this.disableRowView(params)) return '';
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = moment(params.value.iso).format('MMM DD,hh:mm a');
        return eDiv;
      },
    }, {
      headerName: 'Message',
      field: 'referenceMessage',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data || this.disableRowView(params)) {
          return '';
        }
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = params.value;
        return eDiv;
      },
    }, {
      headerName: 'Class',
      width: 70,
      cellRenderer: (params: any): any => {
        if (!params.data) return '';
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        eDiv.innerHTML = '';
        if (this.isRegimenConatainsTablet[params.data.referenceId]?.regimenClass) {
          eDiv.innerHTML = this.isRegimenConatainsTablet[params.data.referenceId]?.regimenClass;
        }
        return eDiv;
      },
    }, {
      headerName: 'Recent Note',
      flex: 1,
      cellRenderer: (params: any): any => {
        if (!params.data || this.disableRowView(params)) return '';
        const eDiv = this.windowRefService.nativeWindow.document.createElement('div');
        const pendingCallType = [ApiClientConstant.PendingCall.Type.InitialOrder,
          ApiClientConstant.PendingCall.Type.AbandonedCart,
          ApiClientConstant.PendingCall.Type.DoctorOrder,
          ApiClientConstant.PendingCall.Type.OrderConfirmation,
          ApiClientConstant.PendingCall.Type.OnlinePaymentPending,
          ApiClientConstant.PendingCall.Type.CanceledOrder];
        if (this.isRegimenConatainsTablet[params.data.referenceId]?.message && pendingCallType.includes(params.data.type)) {
          eDiv.innerHTML = this.isRegimenConatainsTablet[params.data.referenceId]?.message;
        }
        return eDiv;
      },
    }];
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridApi.setGridOption('defaultColDef', { width: 120 });
    this.gridApi.setGridOption('columnDefs', this.columnDefs);
    this.gridApi.setGridOption('cacheBlockSize', this.ui.grid.pageSize);
    this.gridApi.setGridOption('animateRows', true);
    this.gridApi.setGridOption('datasource', this.dataSource);
    this.reset();
  }

  async getRecentNoteAndCheckIfTablet(referenceIds: Array<any>): Promise<void> {
    if (!referenceIds.length) {
      return;
    }
    const payload: RequestQueryPayload<Table.Order> = {
      where: {
        $or: referenceIds,
      },
      include: ['products', 'regimen'],
      project: ['products.isTablet' as 'products', 'notes', 'type', 'regimen.class' as 'regimen'],
    };
    const result = await this.connectionService.findOrders(payload);
    result.forEach((order: any) => {
      const productOfTablet = order.get('products').find((each: any): any => each.get('isTablet'));
      let isTablet: boolean = false;
      if (productOfTablet && order.get('type') === ApiClientConstant.Order.Type.REGIMEN) {
        isTablet = true;
      }
      let message;
      if (order.get('notes')?.length) {
        const recentMessage = order.get('notes')[order.get('notes').length - 1].message;
        message = recentMessage;
      }
      let regimenClass;
      if (order.get('regimen')?.get('class')) {
        regimenClass = order.get('regimen').get('class');
      }
      Object.assign(this.isRegimenConatainsTablet,
        { [order.id]: { isTablet, message, regimenClass } });
    });
  }

  async loadMore(params: any): Promise<Array<{ [key: string]: unknown }>> {
    let data;
    if (this.userRoles.includes(this.appConfig.Shared.Role.Name.ADMIN_OPERATOR)
      || this.userRoles.includes(this.appConfig.Shared.Role.Name.ADMIN)) {
      data = await this.getAllPendingCasesForAdminOperator(params);
    } else {
      const operatorCapturedCases = await this.getOperatorCapturedCases(params);
      const [unCapturedCases]: any = await Promise.all([
        this.getUncapturedCases(params, params.startRow === 0 ? (100 - operatorCapturedCases.length) : 100),
        this.getCountOfPendingCalls(params),
      ]);
      if (params.startRow === 0) {
        this.count += operatorCapturedCases.length;
      }
      data = [...operatorCapturedCases, ...unCapturedCases];
    }
    const referenceIds: Array<any> = [];
    data.forEach((pendingCall: any) => {
      referenceIds.push({ objectId: pendingCall.referenceId });
    });
    await this.getRecentNoteAndCheckIfTablet(referenceIds);
    return data;
  }

  async getAllPendingCasesForAdminOperator(params: any): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.PendingCall> = {
      where: {
        status: ApiClientConstant.PendingCall.Status.Requested,
        requestTime: { $lte: new Date() },
      },
      descending: 'requestTime',
      limit: 100,
      skip: params.startRow,
      include: ['user', 'allocatedOperator'],
      project: ['type', 'referenceId', 'referenceMessage', 'user.MobileNumber' as 'user',
        'user.alternateNumber' as 'user', 'user.PatientName' as 'user', 'user.username' as 'user',
        'user.userType' as 'user', 'user.orderState' as 'user', 'requestTime' as 'user', 'languagePreference',
        'teams', 'user.tags' as 'user', 'user.age' as 'user', 'totalAmountSpent', 'user.mod20' as 'user',
        'allocatedOperator.username' as 'allocatedOperator'],
    };
    this.addSearchFilter(requestPayload);
    this.addTypeFilter(requestPayload);
    this.addLanguageFilter(requestPayload);
    this.addTeamFilter(requestPayload);
    this.addOperatorFilter(requestPayload);
    this.addDoctorFilter(requestPayload);
    const [pendingCalls, count]: any = await Promise.all([
      ApiConnector.find(Table.PendingCall, requestPayload),
      ApiConnector.count(Table.PendingCall, requestPayload),
    ]);
    this.count = count;
    return pendingCalls.map((item: any) => item.toJSON());
  }

  async getCountOfPendingCalls(params: any): Promise<void> {
    if (params.startRow !== 0) {
      return;
    }
    const requestPayload: RequestQueryPayload<Table.PendingCall> = {
      where: {
        status: ApiClientConstant.PendingCall.Status.Requested,
        requestTime: { $lte: new Date() },
        allocatedOperator: { $exists: false },
      },
    };
    this.addSearchFilter(requestPayload);
    this.addLanguageFilter(requestPayload);
    this.addOperatorFilter(requestPayload);
    this.addTeamFilter(requestPayload);
    this.addTypeFilter(requestPayload);
    this.count = await ApiConnector.count(Table.PendingCall, requestPayload);
  }

  async getUncapturedCases(params: any, limit: number): Promise<Array<any>> {
    const requestPayload: RequestQueryPayload<Table.PendingCall> = {
      where: {
        status: ApiClientConstant.PendingCall.Status.Requested,
        requestTime: { $lte: new Date() },
        allocatedOperator: { $exists: false },
      },
      limit,
      skip: params.startRow,
      include: ['user', 'allocatedOperator'],
      project: ['type', 'referenceId', 'referenceMessage', 'user.MobileNumber' as 'user',
        'user.alternateNumber' as 'user', 'user.PatientName' as 'user', 'user.username' as 'user',
        'user.userType' as 'user', 'user.orderState' as 'user', 'requestTime' as 'user', 'languagePreference',
        'teams', 'user.tags' as 'user', 'user.age' as 'user', 'totalAmountSpent', 'user.mod20' as 'user',
        'allocatedOperator.username' as 'allocatedOperator'],
    };
    requestPayload[this.shuffleOrder] = 'requestTime';
    this.addSearchFilter(requestPayload);
    this.addTypeFilter(requestPayload);
    this.addLanguageFilter(requestPayload);
    this.addTeamFilter(requestPayload);
    const unCapturedPendingCalls = await ApiConnector.find(Table.PendingCall, requestPayload);
    return unCapturedPendingCalls.map((item: any) => item.toJSON());
  }

  async getOperatorCapturedCases(params: any): Promise<Array<any>> {
    if (params.startRow !== 0) {
      return [];
    }
    const requestPayload: RequestQueryPayload<Table.PendingCall> = {
      where: {
        status: ApiClientConstant.PendingCall.Status.Requested,
        requestTime: { $lte: new Date() },
        allocatedOperator: this.connectionService.getCurrentUser(),
      },
      limit: 2000,
      include: ['user', 'allocatedOperator'],
      project: ['type', 'referenceId', 'referenceMessage', 'user.MobileNumber' as 'user',
        'user.alternateNumber' as 'user', 'user.PatientName' as 'user', 'user.username' as 'user',
        'user.userType' as 'user', 'user.orderState' as 'user', 'requestTime' as 'user', 'languagePreference',
        'teams', 'user.tags' as 'user', 'user.age' as 'user', 'totalAmountSpent', 'user.mod20' as 'user',
        'allocatedOperator' as 'allocatedOperator'],
    };
    const capturedPendingCalls = await ApiConnector.find(Table.PendingCall, requestPayload);
    return capturedPendingCalls.map((item: any) => item.toJSON());
  }

  updateLanguageFilter(languageFilter: Array<any>): void {
    this.resetLanguageFilter = false;
    this.languageFilter = languageFilter;
    this.reset();
  }

  addLanguageFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>): void {
    const requestPayload = requestPayload_;
    if (this.languageFilter.length) {
      requestPayload.where.languagePreference = { $in: this.languageFilter };
    }
  }

  addTeamFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>): void {
    const requestPayload = requestPayload_;
    if (this.teams.length) {
      requestPayload.where.teams = { $in: this.teams };
    }
  }

  addOperatorFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>): void {
    const requestPayload = requestPayload_;
    if (this.operatorFilter.length) {
      requestPayload.where.allocatedOperator = { $in: this.operatorFilter };
    }
  }

  addDoctorFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>): void {
    const requestPayload = requestPayload_;
    if (this.doctorFilter.length) {
      requestPayload.where.allocatedDoctor = { $in: this.doctorFilter };
    }
  }

  addTypeFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>): void {
    const requestPayload = requestPayload_;
    if (this.typeFilter.length) {
      requestPayload.where.type = { $in: this.typeFilter };
    }
  }

  addSearchFilter(requestPayload_: RequestQueryPayload<Table.PendingCall>): void {
    const requestPayload = requestPayload_;
    if (this.searchKey) {
      requestPayload.where.referenceMessage = { $regex: this.searchKey, $options: 'i' };
    }
  }

  updateTeamFilter(teamFilter: Array<string>): void {
    this.resetTeamFilter = false;
    this.teamFilter = teamFilter;
    this.teams = teamFilter;
    this.reset();
  }

  updateTypeFilter(typeFilter: Array<string>): void {
    this.resetTypeFilter = false;
    this.typeFilter = typeFilter;
    this.reset();
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      clearInterval(this.autoRefreshInterval);
      this.reset();
      this.autoRefreshInterval = setInterval(() => this.reset(), 5000);
    } else clearInterval(this.autoRefreshInterval);
  }

  reset(): void {
    if (!this.gridApi) return;
    const shuffle = Math.floor(Math.random() * 2);
    this.shuffleOrder = shuffle === 1 ? 'ascending' : 'descending';
    this.gridApi.setGridOption('datasource', this.dataSource);
  }

  updateOperatorFilter(operatorFilter: Array<any>): void {
    this.resetOperatorFilter = false;
    this.operatorFilter = operatorFilter;
    this.reset();
  }

  updateDoctorFilter(doctorFilter: Array<any>): void {
    this.resetDoctorFilter = false;
    this.doctorFilter = doctorFilter;
    this.reset();
  }

  resetFilters(): void {
  }
}
