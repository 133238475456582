
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Article Images Upload</h4><i class="fas fa-times pull-right fontL3" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <form role="form" name="messageNoteForm" #imageUploadForm="ngForm">
      <div class="col-xs-12 col-md-10"> 
        <label class="col-xs-12 mt-0">Choose File</label>
        <input class="col-xs-8" type="file" (change)="file = $event.target.files[0]" required="required" placeholder="Article Images Upload"/>
      </div>
      <div class="col-xs-12 col-md-10"> 
        <label class="col-xs-12">UPLOADED IMAGE URL</label>
        <input class="col-xs-8" #url="" placeholder="Name" required="required"/>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" [disabled]="!imageUploadForm.valid" name="submit" (click)="uploadToAws()">Save</button>
  </div>
</mat-dialog-content>