
<div class="df text-center pos-rlt">
  <div class="border py-3 flex-grow">
    <div class="col-xs-12 mb-3">
      <button class="btn-xs ml-3" (mousedown)="startZoomingIn(true)" (mouseup)="stopZooming()" (touchend)="stopZooming()" (touchstart)="startZoomingIn(true)">zoomIn</button>
      <button class="btn-xs btn-primary ml-3" (mousedown)="startZoomingOut(true)" (mouseup)="stopZooming()" (touchend)="stopZooming()" (touchstart)="startZoomingOut(true)">zoomOut</button>
    </div>
    <div class="of-auto dib" #firstImageContainer="" (scroll)="calculateCoordinates()">
      <canvas #firstImage="" id="canvasOne{{params.index}}"></canvas>
    </div>
    <p>{{instantCheckups[0]?.createdAt | date: 'd MMM yyyy'}} , [{{ instantCheckups[0]?.objectId}}]</p>
    <div class="col-xs-12 py-3">
      <div class="col-xs-3">
        <button class="btn-xxs btn-secondary" (click)="rotateLeftImage(0)">rotate left</button>
      </div>
      <div class="col-xs-3">
        <button class="btn-xxs btn-secondary" (click)="rotateRightImage(0)">rotate right</button>
      </div>
      <div class="col-xs-2">
        <button class="btn-xxs btn-secondary" (click)="resetOrientation(0)">reset</button>
      </div>
      <div class="col-xs-3">
        <button class="btn-xxs btn-secondary" (click)="saveOrientation()">save rotation</button>
      </div>
    </div>
    <div class="fontS3 text-center">Note: Rotation will be changed in the original checkup also.</div>
  </div>
  <div class="border py-3 flex-grow">
    <div class="col-xs-12 mb-3"><a class="navigator fas fa-arrow-circle-left pull-left ml-3" (click)="changePhoto(-1)"></a>
      <button class="btn-xs" (mousedown)="startZoomingIn(false)" (mouseup)="stopZooming()" (touchend)="stopZooming()" (touchstart)="startZoomingIn(false)">zoomIn</button>
      <button class="btn-xs btn-primary ml-3" (mousedown)="startZoomingOut(false)" (mouseup)="stopZooming()" (touchend)="stopZooming()" (touchstart)="startZoomingIn(false)">zoomOut</button><a class="navigator fas fa-arrow-circle-right pull-right mr-3" (click)="changePhoto(1)"></a>
    </div>
    <div class="of-auto dib" #secondImageContainer="" (scroll)="calculateCoordinates()">
      <canvas #secondImage="" id="canvasTwo{{params.index}}"></canvas>
    </div>
    <p>{{instantCheckups[1]?.createdAt | date: 'd MMM yyyy'}} , [{{instantCheckups[1]?.objectId}}]</p>
    <div class="col-xs-12 py-3">
      <div class="col-xs-3">
        <button class="btn-xxs btn-secondary" (click)="rotateLeftImage(1)">rotate left</button>
      </div>
      <div class="col-xs-3">
        <button class="btn-xxs btn-secondary" (click)="rotateRightImage(1)">rotate right</button>
      </div>
      <div class="col-xs-2">
        <button class="btn-xxs btn-secondary" (click)="resetOrientation(1)">reset</button>
      </div>
      <div class="col-xs-3">
        <button class="btn-xxs btn-secondary" (click)="saveOrientation()">save rotation</button>
      </div>
    </div>
    <div class="fontS3 text-center">Note: Rotation will be changed in the original checkup also.</div>
  </div>
  <div class="border py-3 flex-grow text-center">
    <div class="bold pb-3 fontL2">Comparison</div>
    <div class="improvement" #container="">
      <div class="img-container">
        <div class="img-holder-1" #imgH1="">
          <canvas #sliderCanvas1=""></canvas>
        </div>
        <div class="img-holder-2" #imgH2="">
          <canvas #sliderCanvas2=""></canvas>
        </div>
      </div>
    </div>
  </div><em class="fas fa-trash" *ngIf="params?.index > 0" (click)="onRemoveComparison.emit()"></em>
</div>