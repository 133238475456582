
<div class="w100 my-4">
  <div class="col-xs-12 scanningHolder p-0" *ngIf="!ui.imageLoaded">
    <div class="scanner"><em></em>
      <div class="scannerLine"></div><span class="posA vh-center fas fa-image" style="font-size:4rem;"></span>
    </div>
  </div>
  <div class="posR" *ngIf="instantCheckup">
    <canvas #canvasIC=""></canvas>
    <canvas class="posA posTL z-ind-1 w100" #canvasIssueMarking=""></canvas>
  </div>
  <div class="mt-4 py-1 of-x-auto" *ngIf="ui.imageLoaded && result.length > 1">
    <div class="concern" *ngFor="let item of result; let i = index" id="{{i}}_card" (click)="drawProblem(item, i)" [ngClass]="{ 'selectedConcern': ui.selectedCard == i}">{{item.ProblemName || item.class}}</div>
  </div>
  <div class="col-xs-12 posR p-0" *ngIf="ui.imageLoaded">
    <div class="col-xs-12 flex justifyC flex-column" *ngIf="userRoles.includes('instantCheckUpDelete') && result.length > 1">
      <ng-container *ngFor="let item of result; let i = index">
        <div class="mt-3 col-xs-6 flex" *ngIf="ui.selectedCard === i && ui.selectedCard !== 0">
          <input class="w50" type="range" step="0.5" min="0" max="10" [value]="sliderValue[item.ProblemName] || 0" [(ngModel)]="sliderValue[item.ProblemName]" (ngModelChange)="sliderChange(item, i, true)"/><span class="text-center px-3">{{(sliderValue[item.ProblemName] || 0) / 10}}</span>
          <button (click)="saveSliderValue(item)">Save threshold</button>
        </div>
      </ng-container>
    </div><img class="w100 mt-3" *ngIf="instantCheckup && !hideFullImage" [src]="instantCheckup.imagePath"/>
    <button class="mt-3" *ngIf="hideFullImage" (click)="hideFullImage = false">Show Full Image</button>
    <button class="mt-3" *ngIf="!hideFullImage" (click)="toggleAiResponse()">Show AI Response</button>
    <div class="mt-3" *ngIf="instantCheckup && showAiResponse">{{response}}</div>
    <div class="col-xs-12 flex mt-2">
      <input class="form-control w40" type="number" placeholder="Image display percentage Eg: 50, 75" [(ngModel)]="displayPercentage" (ngModelChange)="changeDisplay()"/>
      <button class="ml-3" (click)="changeDisplay(true)">save</button>
    </div>
  </div>
</div>
