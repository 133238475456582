
<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title pull-left pr-10">Crop Signature</h4><i class="fas fa-times pull-right fontL3 pl-10" (click)="hideModal()"></i>
  </div>
  <div class="modal-body">
    <div class="flex justifyC mb-4"> 
      <input type="file" (change)="handleChange($event)"/>
    </div>
    <div class="flex mb-4 justifyC">
      <button class="btn-xs btn-primary" (click)="crop()">Crop </button>
      <button class="btn-xs btn-primary ml-2" (click)="reset()">Reset</button>
    </div>
    <div class="flex justifyC alignC">
      <canvas #canvas="" (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUp($event)"> </canvas>
    </div>
  </div>
  <div class="modal-footer"> 
    <div class="flex justifyC" *ngIf="croppedFile"> 
      <button class="btn btn-xs" (click)="saveImage()">Save</button>
    </div>
  </div>
</mat-dialog-content>