
<div class="hbox">
  <div class="voice-recorder">
    <div class="col-xs-12 title" *ngIf="!orderApprovalPage">Voice Note</div>
    <div class="flex col-xs-12" style="padding-bottom: 20px">
      <div class="w25" style="align-items:center;">
        <div class="timer" *ngIf="(ui.recording || !blob) && enableManualRecording">{{ui.recordingTimeInMinutes}}</div>
        <div class="col-xs-12" *ngIf="!ui.recording">
          <div class="col-xs-12" #audioPlayer=""></div>
        </div>
        <div class="buttons flex" *ngIf="enableManualRecording">
          <button class="btn start-btn" *ngIf="!ui.recording && !blob" (click)="startRecording()"><i class="fas fa-microphone"></i><span class="ml-3 fontR">Start Recording</span></button>
          <button class="btn stop-btn" *ngIf="ui.recording" (click)="stopRecording()"><i class="fas fa-microphone"></i><span class="ml-3 fontR">Stop Recording</span></button>
          <button class="btn start-btn" *ngIf="!ui.recording && blob" (click)="startRecording()"><i class="fas fa-microphone"></i><span class="ml-3 fontR">Record More</span></button>
          <button class="btn record-btn" *ngIf="!ui.recording && blob" (click)="resetAudio()"><i class="fas fa-microphone"></i><span class="ml-3 fontR">Reset</span></button>
        </div>
      </div>
      <div class="w25 question" *ngIf="questionForDoc.length >=1"><span style="font-size: 18px; font-weight: 700; margin-bottom: 10px;">Question For Doc: </span>
        <ul style="list-style: none; padding: unset" *ngFor="let question of questionForDoc">
          <li>{{question}}</li>
        </ul>
      </div>
      <div class="w50 voice-notes">
        <ul>
          <li>Intro . - Hi(Pts name). i am dr...</li>
          <li>Address patient queries and questions and reassure - why no results, why so long? etc.</li>
          <li>Drs observations on photos</li>
          <li>Treatment plan - Regimen edits and why</li>
          <li>Prognosis - When to see result an how much result</li>
          <li>continue and take next followup in 4 weeks</li>
          <li>on the affected area</li>
        </ul>
      </div>
    </div>
  </div>
</div>
