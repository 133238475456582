
<div class="app-header navbar" style="z-index: 100;">
  <div class="navbar-header mr-0">
    <div class="menu-bar pull-right visible-sm visible-xs" (click)="app.settings.mobileHeader = !app.settings.mobileHeader"><i class="fa fa-bars"></i></div><a routerLink="/chats/open-tickets"><span><img style="width:90px;margin: 10px auto;" src="https://cdn.cureskin.com/app/img/logo_white.svg" align="middle"/></span></a>
  </div>
  <div class="collapse navbar-collapse m-0 p-0" [ngClass]="{ show: app.settings.mobileHeader }">
    <div class="nav navbar-nav hidden-xs hide"><a class="btn no-shadow navbar-btn" href="" (click)="app.settings.asideFolded = !app.settings.asideFolded"><i class="fa fa-fw {{app.settings.asideFolded ? 'fa-indent' : 'fa-dedent'}}"></i></a></div>
    <ul class="nav navbar-nav navbar-left m-l-xs">
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/chats')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(1)">Operators<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 1}">
          <li [ngClass]="{active: isActive('/chats/open-tickets')}"><a routerLink="/chats/open-tickets">Open Support Tickets</a></li>
          <li [ngClass]="{active: isActive('/chats/outgoing')}"><a routerLink="/chats/outgoing">Outgoing Chats</a></li>
          <li [ngClass]="{active: isActive('/chats/waitlist')}"><a routerLink="/chats/waitlist">WaitList</a></li>
          <li [ngClass]="{active: isActive('/chats/assignedList')}"><a routerLink="/chats/assignedList">Assigned Chats</a></li>
          <li [ngClass]="{active: isActive('/chats/initial-calls')}"><a routerLink="/chats/initial-calls" [queryParams]="{type: appConfig.Shared.MetaBaseQueryType.INITIAL_ORDER_CALLS}">Initial Order Calls</a></li>
          <!--li([ngClass]='{active: isActive("/chats/all")}')-->
          <!--  a(routerLink='/chats/all') All-->
          <li [ngClass]="{active: isActive('/chats/InstantCheckups')}"><a routerLink="/chats/InstantCheckups"> InstantCheckups</a></li>
          <li class="nav-menu-item dropdown-submenu" style="padding:3px;"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(1)">Call List<i class="fa fa-caret-right m-l-xs"></i></a>
            <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 1}">
              <li [ngClass]="{active: isActive('/orders?stage=CONFIRMATION_PENDING')}"><a href="/orders?stage=CONFIRMATION_PENDING">ConfirmationPending</a></li>
              <li [ngClass]="{active: isActive('/orders?stage=DELIVERY_FAIL')}"><a href="/orders?stage=DELIVERY_FAIL"> deliveryFail</a></li>
              <li [ngClass]="{active: isActive('/chats/callList/comingInstantCheckupCallList')}"><a routerLink="/chats/callList/comingInstantCheckupCallList">Coming Instant Checkup</a></li>
            </ul>
          </li>
          <li><a routerLink="/scheduler" [queryParams]="{ languageFilter: true, operatorFilter: true, filter: '{\'MessageBody\':\'SEND_REGIMEN_INSTRUCTION\'}' }">Failed Regimen Instructions</a></li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/order')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(1)">Couriers<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 1}">
          <li [ngClass]="{active: isActive('/orders')}"><a href="{{courierUrl}}"> Orders</a></li>
          <li class="nav-menu-item dropdown-submenu" *ngIf="isOperatorRolePresent" [ngClass]="{active: isActive('/orders', true)}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(1)">Ship Now Excel<i class="fa fa-caret-right m-l-xs"></i></a>
            <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 1}">
              <li><a routerLink="/orders/shipNow" [queryParams]="{type: appConfig.Shared.MetaBaseQueryType.DELHIVERY}">Delhivery</a></li>
              <li><a routerLink="/orders/shipNow" [queryParams]="{type: appConfig.Shared.MetaBaseQueryType.ECOM_EXPRESS}">EcomExpress</a></li>
              <li><a routerLink="/orders/shipNow" [queryParams]="{type: appConfig.Shared.MetaBaseQueryType.EKART}">Ekart</a></li>
              <li><a routerLink="/orders/shipNow" [queryParams]="{type: appConfig.Shared.MetaBaseQueryType.EZ_SLIP}">EZSlip</a></li>
              <li><a routerLink="/orders/shipNow" [queryParams]="{type: appConfig.Shared.MetaBaseQueryType.ALL}">All</a></li>
            </ul>
          </li>
          <li><a (click)="openPincodeFileUploaderDialogue()">Upload Pincode File</a></li>
          <li><a routerLink="/couriers/bulkUpdate">Bulk Action</a></li>
          <li><a routerLink="/orders/refund">Refunds</a></li>
          <li><a routerLink="/couriers/wareHouse">WareHouse</a></li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/products')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(2)">Products<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 2}">
          <li [ngClass]="{active: isActive('/products/regimens', true)}"><a routerLink="/products/regimens">Regimens</a></li>
          <li *ngIf="showKioskCall" [ngClass]="{active: isActive('/products/offlineRegimens', true)}"><a routerLink="/products/offlineRegimens">Offline Regimens</a></li>
          <li [ngClass]="{active: isActive('/products/regimens/sop', true)}"><a routerLink="/products/regimens/sop">Regimen SOP</a></li>
          <li [ngClass]="{active: isActive('/products/catalogs', true)}"><a routerLink="/products/catalogs">Catalog</a></li>
          <li [ngClass]="{active: isActive('/products/services')}"><a routerLink="/products/services">Services</a></li>
          <li [ngClass]="{active: isActive('/products/marketProducts')}"><a routerLink="/products/marketProducts">Market Products</a></li>
          <li [ngClass]="{active: isActive('/skus')}"><a routerLink="/skus">SKUs</a></li>
          <li [ngClass]="{active: isActive('/products/medialink', true)}"><a routerLink="/products/medialink">Media Link</a></li>
          <li><a (click)="openAudioUploadDialog()">Audio Upload</a></li>
          <li [ngClass]="{active: isActive('/products/margId', true)}"><a routerLink="/products/margId">Marg Ids</a></li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/doctor')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(3)">Doctor<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 3}">
          <li [ngClass]="{active: isActive('/orders/approval')}"><a routerLink="/orders/approval">Order approval</a></li>
          <li [ngClass]="{active: isActive('/doctor/followUps/ready')}"><a routerLink="/doctor/followUps/ready">FollowUps - Ready</a></li>
          <li [ngClass]="{active: isActive('/chats/open-tickets')}"><a routerLink="/chats/open-tickets">Open Support Tickets</a></li>
          <li [ngClass]="{active: isActive('/dashboard')}"><a routerLink="/dashboard">Dashboard</a></li>
          <li [ngClass]="{active: isActive('/orders/approval?stage=CONSULTATION_CREATED')}"><a routerLink="/orders/approval" [queryParams]="{stage: appConfig.Shared.Order.Stage.CONSULTATION_CREATED}">Consultations</a></li>
          <li [ngClass]="{active: isActive('/products/featuredProducts')}"><a routerLink="/products/featuredProducts">Featured Products</a></li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/tree')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(4)">Tree<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 4}">
          <li [ngClass]="{active: isActive('/trees/question')}"><a routerLink="/trees/questions">Questions</a></li>
          <li [ngClass]="{active: isActive('/trees/main-concerns')}"><a routerLink="/trees/main-concerns">Main Concerns</a></li>
          <li [ngClass]="{active: isActive('/trees/trigger-by-keyword')}"><a routerLink="/trees/trigger-by-keyword">Trigger-By-Keyword</a></li>
          <li [ngClass]="{active: isActive('/tree/v5')}"><a routerLink="/tree/v5">Indications</a></li>
          <li (click)="openNewTab(conn.getParseUrl() + '/api/tree')"><a>Tree JSON</a></li>
          <li (click)="openNewTab(conn.getParseUrl() + '/api/tree/predefinedQuestion')"><a>Predefined Question</a></li>
          <li (click)="validateTreeJSON()"><a>Validate</a></li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown" style="padding:3px;"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(5)"> Marketing<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 5}">
          <li [ngClass]="{active: isActive('/marketing/article')}"><a routerLink="/marketing/article">Articles</a></li>
          <li [ngClass]="{active: isActive('/marketing/FAQ')}"><a routerLink="/marketing/FAQ">FAQ</a></li>
          <li [ngClass]="{active: isActive('/marketing/support')}"><a routerLink="/marketing/support">Support</a></li>
          <li [ngClass]="{active: isActive('/marketing/quiz-questions')}"><a routerLink="/marketing/quiz-questions">Quiz Game</a></li>
          <li><a (click)="openArticleFileUploaderDialogue()">Upload Article Image</a></li>
          <li [ngClass]="{active: isActive('/marketing/explore')}"><a routerLink="/marketing/explore">Explore Posts</a></li>
          <li><a (click)="openBulkArticle()">Bulk Article</a></li>
          <li><a (click)="openPushNotification()">Push Notification</a></li>
          <li><a routerLink="/reminder">Reminders</a></li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/users')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(2)">Users<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 2}">
          <li [ngClass]="{active: isActive('/users', true)}"><a routerLink="/users">List</a></li>
          <li [ngClass]="{active: isActive('/users/internal-users')}"><a routerLink="/users/internal-users">Internal Users</a></li>
          <li [ngClass]="{active: isActive('/users/doctors')}"><a routerLink="/users/doctors">Doctors</a></li>
          <li [ngClass]="{active: isActive('/users/leaves')}"><a routerLink="/users/leaves">Add Leaves And Holidays</a></li>
          <li [ngClass]="{active: isActive('/doctor/internalTeam')}"><a routerLink="/doctor/internalTeam">Internal Teams</a></li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/languageStrings') || isActive('/languageVoice')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(2)">Language<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 2}">
          <li class="nav-menu-item dropdown-submenu" [ngClass]="{active: isActive('/languageStrings')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(8)">Text<i class="fa fa-caret-right m-l-xs"></i></a>
            <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 8}">
              <li [ngClass]="{active: isActive('/languageStrings', true)}"><a routerLink="/languageStrings">List</a></li>
              <li [ngClass]="{active: isActive('/languageStrings/tags', true)}"><a routerLink="/languageStrings/tags">Tags</a></li>
              <li [ngClass]="{active: isActive('/languageStrings/approval', true)}"><a routerLink="/languageStrings/approval">Approval Pending</a></li>
              <li [ngClass]="{active: isActive('/cannedResponses', true)}"><a routerLink="/cannedResponses">Canned Response</a></li>
            </ul>
          </li>
          <li class="nav-menu-item dropdown-submenu" [ngClass]="{active: isActive('/languageVoice')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(9)">Voice<i class="fa fa-caret-right m-l-xs"></i></a>
            <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 9}">
              <li [ngClass]="{active: isActive('/languageVoices', true)}"><a routerLink="/languageVoices">List</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/ecom')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(2)">E-Com<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 2}">
          <li [ngClass]="{active: isActive('/ecom/coupon', true)}"><a routerLink="/ecom/coupons">Coupons</a></li>
        </ul>
      </li>
      <li class="nav-menu-item" *ngIf="user?.get('TipsDocument') && user.get('type') === 'doctor'" (click)="openNewTab(user.get('TipsDocument'))"><span>Tips</span></li>
      <li class="nav-menu-item dropdown" [ngClass]="{active: isActive('/calls')}"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(7)">Calls<i class="fa fa-caret-down m-l-xs"></i></a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 7}">
          <li [ngClass]="{active: isActive('/calls/incoming-outgoing', true)}"><a routerLink="calls/incoming-outgoing">Incoming/Outgoing</a></li>
          <li [ngClass]="{active: isActive('/calls/pending-calls', true)}"><a routerLink="calls/pending-calls">Pending Calls</a></li>
          <li [ngClass]="{active: isActive('/calls/pending-calls', true)}"><a routerLink="calls/old-pending-calls">Old Pending Calls</a></li>
        </ul>
      </li>
      <li class="nav-menu-item dropdown"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(6)">&bull;	&bull;	&bull;</a>
        <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 6}" style="left: -200%;">
          <li (click)="copyToken()"><a class="hv"><span>Copy Token</span>
              <input class="pos-fix" #token="" style="top:-100%;left:-100%;"/></a></li>
          <li routerLinkActive="active" routerLink="/easyAccess"><a class="hv">Bookmark</a></li>
          <li routerLinkActive="active" routerLink="/orders/stages"><a class="hv">Order Stages</a></li>
          <li routerLinkActive="active" routerLink="/experiment"><a class="hv">Experiments</a></li>
          <li *ngIf="isOperatorRolePresent" routerLinkActive="active" routerLink="/analytics"><a class="hv">Analytics</a></li>
          <li class="dropdown-submenu"><a class="dropdown-toggle" data-toggle="dropdown" (click)="toggleSelection(7)">Scheduler<i class="fa fa-caret-right m-l-xs"></i></a>
            <ul class="dropdown-menu clearfix no-dropdown-xs" role="menu" [ngClass]="{block: menuItemSelected === 7}" style="left: -102%;">
              <li><a routerLink="/scheduler">EventScheduler</a></li>
              <li><a routerLink="/scheduler">BatchRequest</a></li>
            </ul>
          </li>
          <li (click)="openPassword()"><a class="hv">Change password</a></li>
          <li (click)="logOut()"><a class="hv">Logout</a></li>
        </ul>
      </li>
    </ul>
  </div>
</div>
