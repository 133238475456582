
<div class="hbox" style="background: #ced1db">
  <div class="div new-dashboard" style="border-radius: 20px">
    <div class="div sidebar" id="mySidebar">
      <div class="div" style="display: flex; flex-direction: column;">
        <div class="div" style="padding: 10px;display: flex;gap: 10px;align-items: center;justify-content: space-between;"><img class="drImg" style="width: 25px;" [src]="currentUser?.get('doctorDisplayImage')"/><span style="font-size: 14px; max-width: 130px;">{{currentUser?.get('DoctorDisplayName') || currentUser.get('username')}}</span>
          <button class="closeBtn" (click)="toggleNav()">×</button>
        </div>
      </div>
      <div class="div leaves-list">
        <dashboard-leave class="mT3" [user]="user" [message]="'MBBS On Leave Today '" [startDate]="todayDate.start" [endDate]="todayDate.end" [userType]="'MBBS'"></dashboard-leave>
        <dashboard-leave class="mT3" [user]="user" [message]="'DERMATS On Leave Today '" [startDate]="todayDate.start" [endDate]="todayDate.end" [userType]="'DERMATOLOGIST'"></dashboard-leave>
        <dashboard-leave class="mT3" [user]="user" [message]="'Doctors On Leaves Tomorrow'" [startDate]="tomorrowDate.start" [endDate]="tomorrowDate.end"></dashboard-leave>
        <div class="div mbbs-leaves"></div>
      </div>
      <div class="div" style="display: flex; align-items: center; padding-bottom: 10px; justify-content: space-around;"> <img src="../../assets/images/web-logo.png" style="width: 70px;"/></div>
    </div>
    <div class="div main-dashboard" id="main">
      <div class="div flex" id="hamburger">
        <button class="openbtn" (click)="toggleNav()">☰</button>
      </div>
      <div class="div flex" style="flex-flow: column !important; width: 100%;">
        <div class="div dashboard-selections" *ngIf="userRoles.includes('admin') || userRoles.includes('adminDoctor') || teamLeadIds.includes(currentUser.id)">
          <div class="div team-selection">
            <div class="div custom-select">
              <select class="form-control" *ngIf="userRoles.includes('admin') || userRoles.includes('adminDoctor') || teamLeadIds.includes(currentUser.id)" (change)="teamSelectionChanged($event)" [disabled]="!allowReSelection.team" required="required">
                <option value="" selected="selected" disabled="disabled">Team</option>
                <option *ngFor="let team of teams;let i=index;" [value]="i">{{team.get('name')}}</option>
              </select>
            </div>
            <div class="div custom-select">
              <label class ="fontR fw-400">Dermat:</label>
              <select *ngIf="userRoles.includes('admin') || userRoles.includes('adminDoctor') || teamLeadIds.includes(currentUser.id)" (change)="doctorSelectionChanged(true)" [(ngModel)]="username" [disabled]="!allowReSelection.team || !selectedTeam">
                <option [value]="" disabled="disabled" selected="selected">Select Dermat</option>
                <option *ngFor="let doctor of dermatDoctors" [value]="doctor.get('username')">{{doctor?.get('DoctorDisplayName') || doctor?.get('username')}} {{onlineDoctors.includes(doctor.get("username")) ? '🟢' : ''}}</option>
              </select>
            </div>
            <div class="div custom-select">
              <label class ="fontR fw-400">MBBS:</label>
              <select *ngIf="userRoles.includes('admin') || userRoles.includes('adminDoctor') || teamLeadIds.includes(currentUser.id)" (change)="doctorSelectionChanged(false)" [(ngModel)]="username" [disabled]="!allowReSelection.team">
                <option [value]="" disabled="disabled" selected="selected">Select MBBS</option>
                <option *ngFor="let doctor of mbbsDoctors" [value]="doctor.get('username')">{{doctor?.get('DoctorDisplayName') || doctor?.get('username')}} {{onlineDoctors.includes(doctor.get("username")) ? '🟢' : ''}}</option>
              </select>
            </div>
          </div>
          <div><span class="fontR fw700 bold"><a href="https://docs.google.com/spreadsheets/d/1qM9cyY15I65wPNn_pF9M6tCxpg2nWB9Iq3zLQQtzpdo/edit#gid=0" target="_blank">Working Hours</a></span></div>
          <div class="div contact-info" style="display: flex;"><img style="width: 25px; height: 25px;" src="../../assets/images/mail.png"/><img class="ml-2" style="width: 25px; height: 25px;" src="../../assets/images/call.png"/></div>
        </div>
        <div class="div items">
          <div *ngIf="userRoles.includes('admin') || userRoles.includes('adminDoctor') || teamLeadIds.includes(currentUser.id)" class="div date-picker">
            <mat-form-field style="margin-top: 0px !important; padding: 0px !important;">
              <mat-label style="margin-top: 0px !important; font-size: 12px !important;">Enter a date range</mat-label>
              <mat-date-range-input class="pT10" [rangePicker]="picker" [formGroup]="range">
                <input matStartDate="matStartDate" formControlName="start" placeholder="Start date" (ngModelChange)="onChangeDate()"/>
                <input matEndDate="matEndDate" formControlName="end" placeholder="End date" (ngModelChange)="onChangeDate()"/>
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix="matIconSuffix" [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="div mT20">
            <div class="div col-xs-12 dashboard-items">
              <dashboard-order *ngIf="user.get('userType').includes('DERMATOLOGIST') || userRoles.includes('admin') || userRoles.includes('adminDoctor')" [userDetails]="userDetails"></dashboard-order>
              <dashboard-followup [userDetails]="userDetails"></dashboard-followup>
              <dashboard-ticket [userDetails]="userDetails"></dashboard-ticket>
              <dashboard-order-created [userDetails]="userDetails"></dashboard-order-created>
              <dashboard-callLog [userDetails]="userDetails"></dashboard-callLog>
            </div>
            <div class="div col-xs-12 dashboard-items justifySA">
              <dashboard-activity [userDetails]="userDetails" style="display: flex; flex: 1 1 0; gap: 40px;"> </dashboard-activity>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
