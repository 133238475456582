
<div class="col-xs-12 p-0 bg-white h-100">
  <div class="col-xs-12 pt-4 pb-4 of-scr" *ngIf="followupChat?.length" style="overflow-x: hidden; padding-left: 34px; padding-right: 40px;">
    <div class="col-xs-12 flex">
      <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">Primary Doctor :</div>
      <div class="col-xs-7"><span class="pr-2">Dr. {{user.get('allocatedDoctor')?.get('username')?.split('_')[0]}}</span></div>
    </div>
    <div class="col-xs-12 flex">
      <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">Active Regimens :</div>
      <div class="col-xs-7"><span class="pr-2">{{activeRegimens.join(', ').toLowerCase()}}</span></div>
    </div>
    <div class="col-xs-12 p-0 flex fontR" *ngFor="let row of followupChat;let i = index;">
      <div class="col-xs-12 flex" *ngIf="row.value" [ngClass]="{ 'clr-red': row.highlight }">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">{{displayFollowUpQuestions[row.key.toUpperCase()] || row.key}} :</div>
        <div class="col-xs-7"><span class="pr-2">{{row.value}}</span><span class="pr-2" *ngIf="row.date && row.value">{{row.date | amDateFormat: 'MMM DD, YYYY hh:mm a'}}</span></div>
      </div>
    </div>
    <div class="col-xs-12 p-0 flex fontR" *ngFor="let row of followUpReports;let i = index;">
      <div class="col-xs-12 flex mt-2">
        <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">{{row.key}} :</div>
        <div class="col-xs-7">
          <button class="btn-primary-outline"><a href="/user/follow-up-report/{{row.value}}" target="_blank">Open Report</a></button><a class="fas fa-edit ml-3" *ngIf="row.editable" [href]="row.editLink" target="_blank"></a>
        </div>
      </div>
    </div>
    <div class="col-xs-12 mt-2">
      <div class="col-xs-5 ellipsis px-3 py-2 text-cap fw-700">FollowUp history :</div>
      <div class="col-xs-7">
        <button class="btn-primary-outline" (click)="openFollowUpHistory(appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP)">Open History</button>
      </div>
    </div>
  </div>
</div>
