
<p class="auto-face-title">Front face comparision</p>
<div class="auto-face-container-empty posR" *ngIf="instantCheckupIds?.length !== 2">
  <div class="beforeEmpty" (click)="selectBeforeImage.emit(true)">
    <p>+</p>
    <p>Add Photo</p>
  </div>
  <div class="sliderEmpty">
    <div class="slider-handle"><i> <img src="../../../../assets/images/handle.png"/></i></div>
  </div>
  <div class="afterEmpty" (click)="selectAfterImage.emit(true)">
    <p>+</p>
    <p>Add Photo</p>
  </div>
</div>
<div class="auto-face-container" *ngIf="instantCheckupIds?.length === 2">
  <canvas class="before" #canvasBefore="" [appAlignFace]="instantCheckupBefore" [side]="'left'" (imageConfig)="setImageConfig($event, 'before')"></canvas>
  <canvas class="after" [appAlignFace]="instantCheckupAfter" [side]="'right'" (imageConfig)="setImageConfig($event, 'after')"></canvas>
  <div class="slider" #slider="" (mousedown)="slideReady($event, canvasBefore, slider)" (mouseup)="slideFinish($event)">
    <div class="slider-handle"><i><img src="../../../../assets/images/handle.png"/></i></div>
  </div>
</div>
<p *ngIf="instantCheckupIds?.length === 1">One image slected, Please select one more for comparision.</p>
<div class="caption-container">
  <p class="caption"><span>Before</span><span class="caption-date" *ngIf="instantCheckupIds?.length === 2">({{instantCheckupBefore?.createdAt | date: 'dd MMM,YYYY'}})</span></p>
  <p class="caption"><span>After</span><span class="caption-date" *ngIf="instantCheckupIds?.length === 2">({{instantCheckupAfter?.createdAt | date: 'dd MMM,YYYY'}})</span></p>
</div>