
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="col-xs-12 list-header"><span class="pR10">Tags</span>
      <div class="pull-right"><i class="fa fa-plus-square-o m-l-xs pointer" *ngIf="!ui.create.show" (click)="ui.create.show = true"></i></div>
    </div>
    <div class="col-xs-12 my-4" *ngIf="ui.create.show">
      <div class="flex justifyC">
        <input class="col-xs-4" [(ngModel)]="ui.create.tagName" name="newTagName" placeholder="Type a tagname..."/>
        <button class="btn btn-xs btn-success m-l-xs" (click)="createNewTag()">Create</button>
        <button class="btn btn-xs btn-danger m-l-xs" (click)="ui.create.show = false">Cancel</button>
      </div>
    </div>
  </div>
  <div class="flex-content">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content">
          <div>
            <label class="db">Search</label>
            <input class="w100" placeholder="Search..." (change)="reset()" [(ngModel)]="searchKey"/>
          </div>
        </div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" #agGrid="" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
  <div class="flex-footer">
    <div class="snackbar" #toast="">{{message}}</div>
  </div>
</div>