
<div class="col-xs-12 p-0" *ngIf="!showNewUI">
  <div class="hbox" fillComponentParentView="fillComponentParentView">
    <div class="flex-box" *ngIf="user && followUp">
      <div class="flex-heading">
        <div class="col-xs-12 list-header">
          <div class="pull-left bold-600">
            <span>Patient history &nbsp;&nbsp;</span>
            <span class="text-capitalize fontS">{{user.get('PatientName')}}</span>
            <span class="text-capitalize fontS" *ngIf="user.get('Age')">/{{user.get('Age')}}</span>
            <span class="text-capitalize fontS" *ngIf="user.get('Gender')">/{{user.get('Gender')}}</span>
            <span class="text-capitalize fontS" *ngIf="user.get('languagePreference')">/{{user.get('communicationLanguagePreference') || user.get('languagePreference')}}</span>
            <span class="text-capitalize fontS" *ngIf="user.get('lastLocationCity')">/{{user.get('lastLocationCity')}}</span>
            <span class="text-capitalize fontS" *ngIf="user.get('lastLocationState')">/{{user.get('lastLocationState')}}/</span>
            <span class="text-capitalize fontS clr-red" *ngIf="user.get('marketingTags')?.includes('STEROID')">&nbsp; STEROID</span>
            <ng-container *ngIf="followUp?.get('regimens')?.length">
              <button class="bg-primary fontS ml-3 text-center text-lowercase clr-white" *ngFor="let regimen of followUp.get('regimens')">{{regimen.get('class')}}</button>
            </ng-container>
            <ng-container *ngIf="extraData.dissatisfiedUser">
              <button class="bg-red fontS ml-3 text-center text-upr clr-white">DISSATISFIED USER</button>
            </ng-container>
          </div>
          <div class="fontL2 text-cap pull-right flex min-100"><span class="alert-danger bold col-xs-3 bg-red clr-white fontS mr-3" *ngIf="followUp?.get('doctorCallNeeded')">Doctor Call Needed</span><span class="mobile call-icon col-xs-2"><em class="fas fa-phone-alt" *ngIf="user.get('MobileNumber')" (click)="callUserThroughExotel(user.get('MobileNumber'))" title="Mobile"></em><em class="fas fa-phone-alt alt-2" *ngIf="user.get('alternateNumber')" (click)="callUserThroughExotel(user.get('alternateNumber'))" title="Alternate Mobile"></em></span>
            <button class="btn-xs btn-primary-outline mr-3 mb-2 ml-3" *ngIf="followUp && followUp.get('ready')" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP)">Done & Create New</button>
            <button class="btn-xs btn-primary-outline ml-3" *ngIf="followUp" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.WAITING_FOR_IMAGE)">Request Instant Checkup / Image Upload</button>
            <button class="btn-xs btn-primary-outline ml-3"><a routerLink="/chat/{{user.id}}" target="_blank">Chat</a></button>
          </div>
        </div>
      </div>
      <div class="flex-content">
        <div class="col-xs-12 flex alignFS flex-wrap">
          <div class="col-md-8 col-xs-8 p-4">
            <div class="col-xs-12 flex" *ngIf="followUp">
              <div class="col-xs-8 flex" *ngIf="userRoles.includes('adminDoctor') && !followUp?.get('doctorConfirmed')">
                <label class="col-xs-3">Label</label>
                <input-multi-select [parseObj]="followUp" name="label" [options]="labelType"></input-multi-select>
                <button class="btn-xs ml-3" (click)="saveFollowup()">save</button>
              </div>
              <div class="col-xs-4 flex" *ngIf="!followUp.get('doctorConfirmed')">
                <button class="btn-xs ml-3" (click)="captureFollowup()">capture</button>
              </div>
            </div>
            <div class="col-xs-12">
              <div class="col-xs-6 flex" *ngIf="followUp?.get('allocatedDoctor')">
                <label class="col-xs-8">Primary Doctor</label>
                <div class="col-xs-4 ml-3">{{user.get('allocatedDoctor')?.get('username') || '-'}}</div>
              </div>
              <div class="col-xs-6 flex" *ngIf="followUp?.get('allocatedDoctor')">
                <label class="col-xs-8">Allocated Followup Doctor</label>
                <div class="col-xs-4 ml-3">{{followUp?.get('allocatedDoctor')?.get('username') || '-'}}</div>
              </div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Main concern</label>
              <div class="col-xs-8 ml-3"> {{user.get('PrivateMainConcern')?.split('_').join(' ').toLowerCase()}}</div>
            </div>
            <div class="col-xs-12 flex clr-red" *ngIf="thyroidOrSeriousIllness.value">
              <label class="col-xs-4">thyroid Or Serious Illness</label>
              <div class="col-xs-8 ml-3">{{thyroidOrSeriousIllness.value}}({{thyroidOrSeriousIllness.updatedAt | amDateFormat: 'MMM DD, YYYY hh:mm a'}})</div>
            </div>
            <div class="col-xs-12 flex clr-red" *ngIf="sensitiveSkin">
              <label class="col-xs-4">sensitiveSkin</label>
              <div class="col-xs-8 ml-3">{{sensitiveSkin}}</div>
            </div>
            <div class="col-xs-12 flex" *ngIf="user.get('Gender') === 'Female'">
              <label class="col-xs-4">Pregnancy</label>
              <div class="col-xs-8 ml-3" [ngClass]="{'clr-red': extraData.pregnancy }"> {{extraData.pregnancy}}</div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Hormonal</label>
              <div class="col-xs-8 ml-3">{{femaleHormonal || '-'}}</div>
            </div>
            <div class="col-xs-12 flex">
              <label class="col-xs-4">Married</label>
              <div class="col-xs-8 ml-3">{{extraData.married}}</div>
            </div>
            <div class="col-xs-12 flex clr-red" *ngIf="followUp?.get('followUpBodyImprovement')">
              <label class="col-xs-4">FollowUp Body Improvement</label>
              <div class="col-xs-8 ml-3">{{followUp?.get('followUpBodyImprovement')}}</div>
            </div>
            <div class="col-xs-12 flex clr-red" *ngIf="followUp?.get('followUpHairImprovement')">
              <label class="col-xs-4">FollowUp Hair Improvement</label>
              <div class="col-xs-8 ml-3">{{followUp?.get('followUpHairImprovement')}}</div>
            </div>
            <div class="col-xs-12 flex clr-red" *ngIf="followUp?.get('followUpFaceImprovement')">
              <label class="col-xs-4">FollowUp Face Improvement</label>
              <div class="col-xs-8 ml-3">{{followUp?.get('followUpFaceImprovement')}}</div>
            </div>
            <div class="col-xs-12 flex clr-red" *ngIf="followUp?.get('ProgressCheckFaceAppointment')">
              <label class="col-xs-4">Any Problem Facing</label>
              <div class="col-xs-8 ml-3">{{followUp?.get('ProgressCheckFaceAppointment')}}</div>
            </div>
            <div class="col-xs-12 flex clr-red" *ngIf="followUp?.get('anyOtherConcernInFollowup')">
              <label class="col-xs-4">Any Other Query</label>
              <div class="col-xs-8 ml-3">{{followUp?.get('anyOtherConcernInFollowup')}}</div>
            </div>
            <div class="col-xs-12 flex">
              <div class="col-xs-6 flex">
                <label class="col-xs-8 pr-3">Ticket History</label><i class="fa fa-history" (click)="openSupportTicketHistory()"></i>
              </div>
              <div class="col-xs-6 flex">
                <label class="col-xs-8 pr-3">FollowUp History</label><i class="fa fa-history" (click)="openFollowUpModal(appConfig.FollowUp.PopupType.HISTORY_FOLLOWUP)"></i>
              </div>
            </div>
            <div class="col-xs-12">
              <blocked-bot-assistant-modal [user]="user" [hideChatStatus]="true" [(updateStatus)]="updateStatus"></blocked-bot-assistant-modal>
            </div>
            <div class="col-xs-12">
              <div class="col-xs-6 p-0">
                <div class="col-xs-12">
                  <label>Doctor Notes</label>
                </div>
                <user-notes-doctor [user]="user" [enableAddNote]="true"></user-notes-doctor>
              </div>
              <div class="col-xs-6 p-0">
                <div class="col-xs-12">
                  <label>Operator Notes</label>
                </div>
                <user-notes-operator [user]="user"></user-notes-operator>
              </div>
            </div>
            <div class="col-xs-12 flex" *ngIf="orderMedicalRecord.length">
              <label class="col-xs-6">Sop Questions</label>
              <div class="col pull-left">:</div>
              <div class="col-xs-8 flex flex-wrap" *ngFor="let medicalRecords of orderMedicalRecord"><span *ngFor="let response of sopFieldsToShow">{{response}}({{medicalRecords[response]}})&nbsp;</span>
                <div class="col-xs-12"></div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="list-header" style="min-height:40px !important; padding: 0px !important">
              <div class="col-xs-12 flex justifySB">
                <select class="form-control col-xs-4 px-2" [(ngModel)]="selectedFollowup" name="followUps" (ngModelChange)="updateIframe()" style="min-height:20px !important; padding: 0px !important">
                  <option *ngFor="let followUp of followUps" [value]="followUp.id">followUp {{followUp.get('followUpCount')}}<span *ngIf="followUp.get('State') == appConfig.Shared.FollowUp.State.FINISHED">({{followUp.get('allocatedDoctor').get('username')}})</span></option>
                </select>
                <select class="form-control col-xs-4 px-2" [(ngModel)]="selectedChatType" name="selectedChatType" style="min-height:20px !important; padding: 0px !important">
                  <option value="userChat">userChat</option>
                  <option value="chatSummary">chatSummary<span *ngIf="followUp.get('State') == appConfig.Shared.FollowUp.State.FINISHED">({{followUp.get('allocatedDoctor').get('username')}})</span></option>
                </select>
              </div>
            </div>
            <iframe class="hbox" #newChatView *ngIf="selectedChatType === 'userChat' && webAppUrl" [src]="webAppUrl" style="height:550px"></iframe>
            <followup-chat-view-summary *ngIf="selectedChatType === 'chatSummary'" [followup]="selectedFollowup" [user]="user"></followup-chat-view-summary>
          </div>
        </div>
        <div class="col-xs-12 list-header pl-4 m-2" *ngIf="false">
          <tree-trigger [user]="user"></tree-trigger>
        </div>
        <div class="col-xs-12 list-header pl-4">Orders<a class="fa fa-plus-square-o pl-3" routerLink="/orders/new" [queryParams]="{ username: user.get('username') }" target="_blank"></a><span class="ml-3">
            <select [(ngModel)]="selectedOrderType">
              <option *ngFor="let type of orderType" [value]="type">{{type}}</option>
            </select></span></div>
        <div class="col-xs-12 p-4 orderList">
          <order-list [user]="user" [hideTitle]="true" [selectedOrderType]="selectedOrderType"></order-list>
        </div>
        <div class="col-xs-12 p-0" *ngIf="regimens.length">
          <div class="col-xs-12 list-header pl-4">Regimens</div>
          <div class="col-xs-12 p-4">
            <regimen-list [user]="user" [ui]="{hideTitle: true}"></regimen-list>
          </div>
        </div>
        <div class="col-xs-12 list-header mb-2 pl-4">ACTIVITY</div>
        <div class="col-xs-12" *ngFor="let data of userData; let ii = index">
          <button class="btn-xs btn-primary-outline my-3 ml-3" *ngIf="ii===5" (click)="show = !show">Show All</button>
          <ng-container *ngIf="(show && ii>4) || ii<=4">
            <div *ngFor="let item of data; let i = index">
              <div class="col-xs-12 list-header py-0" *ngIf="item.followup">
                <div class="col-xs-4">
                  <label class="mr-1 mb-3">Followup :</label><span class="fontS1">{{item.followup[0].timeline | date: 'd MMM yyyy' }} ( {{item.followup[0].followup.get('State')}} )</span>
                </div>
                <div class="col-xs-2">
                  <label class="mr-1 mb-3">Followup No :</label><span class="fontS1">{{item.followup[0].followup.get('followUpCount')}}</span>
                </div>
                <div class="col-xs-4">
                  <label class="mr-1 mb-3">Improvement :</label><span class="fontS1">{{item.followup[0].followup.get('ProgressCheckFaceAndHairFollowup')|| item.followup[0].followup.get('ProgressCheckFaceFollowup') || item.followup[0].followup.get('ProgressCheckHairFollowup')}}</span>
                </div>
                <div class="col-xs-2">
                  <label class="mr-1 mb-3">Call / Chat:</label><span class="fontS1">{{item.followup[0].followup.get('FollowupChatOrCall') || '-'}}</span>
                </div>
              </div>
              <div class="col-xs-12 bdr-btm" *ngIf="item.deletedRegimen && item.deletedRegimen.length">
                <div>
                  <label class="col-xs-12">Deleted Regimen</label><span>{{item.deletedRegimen[0].timeline | date: 'd MMM yyyy' }}</span>
                </div>
                <div *ngFor="let delReg of item.deletedRegimen">
                  <label class="col-xs-12">Concern - {{delReg.deletedRegimen.concern}}</label>
                  <label class="col-xs-12">Deleted On - {{delReg.deletedRegimen.deletionDate | date: 'd MMM yyyy' }}</label>
                </div>
              </div>
              <div class="col-xs-12 bdr-btm" *ngIf="item.actionLog && item.actionLog.length">
                <label class="col-xs-12">Regimen Changes</label>
                <div class="col-xs-2"><span class="fontS2">{{item.actionLog[0].timeline | date: 'd MMM yyyy' }}</span></div>
                <div class="col-xs-10">
                  <div class="pb-2" *ngFor="let actionLog of item.actionLog">{{actionLog.actionLog.get('Action')}}</div>
                </div>
              </div>
              <div class="col-xs-12 bdr-btm" *ngIf="item.operatorNotes && item.operatorNotes.length">
                <label class="col-xs-12">Operator notes<i class="fa fa-eye ml-3" (click)="showNotes = !showNotes"></i></label>
                <div class="col-xs-2" *ngIf="showNotes"><span class="fontS2">{{item.operatorNotes[0].timeline | date: 'd MMM yyyy' }}</span></div>
                <div class="col-xs-10" *ngIf="showNotes">
                  <div class="pb-2" *ngFor="let operatorNotes of item.operatorNotes">{{operatorNotes.operatorNotes.get('message')}} - added by ( {{operatorNotes.operatorNotes.get('operatorName')}} )</div>
                </div>
              </div>
              <div class="col-xs-12 bdr-btm" *ngIf="i === data.length - 1 && item.instantCheckups && item.instantCheckups.length">
                <div class="col-xs-12">
                  <label>Instant Checkups :</label>
                  <label class="pl-4 pointer clr-link" *ngIf="instantCheckupsCompareIds.length >= 1" (click)="clearInstantCheckupsCompareIds()">Clear</label>
                  <label class="pl-4 pointer clr-link" *ngIf="instantCheckupsCompareIds.length >= 1 && instantCheckupsCompareIds.length % 2 == 0" (click)="openCompareImages()">Compare</label>
                  <button class="btn-xxs btn-secondary mx-4" (click)="automaticCompare('face')">Face Auto</button>
                  <button class="btn-xxs btn-secondary mx-4" (click)="automaticCompare('hair')">Hair Auto</button>
                  <button class="btn-xxs btn-secondary mx-4" (click)="automaticCompare('body')">Body Auto</button>
                </div>
                <div class="col-xs-12 flex of-x-auto">
                  <div class="dib mr-4" *ngFor="let instantCheckup of item.instantCheckups;" style="max-height:400px;min-width:100px" [ngClass]="{'checkup-selected': instantCheckupsCompareIds.includes(instantCheckup.instantCheckup.id)}" [ngStyle]="{'background-color' : instantCheckup.instantCheckup.get('tags')?.includes('GOOD') || instantCheckup.instantCheckup.get('tags')?.includes('AI_GOOD')? '#e4fbef': ''}">
                    <div class="col-xs-12 my-1 text-center"><span class="fontS2">{{instantCheckup.timeline | date: 'd MMM, HH:MM a' }}</span></div>
                    <div class="col-xs-12">
                      <image [data]="instantCheckup.instantCheckup.get('tempThumbnailImagePath') || instantCheckup.instantCheckup.get('tempImagePath')" [width]="instantCheckup.instantCheckup.get('tempThumbnailImagePath') ? '100%' : '100px'" (click)="addImagesToComparison($event, instantCheckup)" style="max-height:140px"></image>
                    </div>
                    <div class="col-xs-12 text-center my-1"><span class="textC fontS2 col-xs-12 mt-0">{{instantCheckup.instantCheckup.get('type')}}<i class="fa fa-pencil-square-o px-2" (click)="openInstantCheckupEditPopup(instantCheckup.instantCheckup.id, instantCheckup.instantCheckup.get('tempThumbnailImagePath') || instantCheckup.instantCheckup.get('tempImagePath'))"></i><i class="fa fa-trash-o ml-2" (dblclick)="deleteInstantCheckUp(instantCheckup.instantCheckup)"></i></span>
                      <label class="col-xs-12 mt-0 clr-link" (click)="openInstantCheckup(instantCheckup)">Open</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 bdr-btm" *ngIf="i === data.length - 1 && item.progressReport && item.progressReport.length">
                <label class="col-xs-12">Progress report :</label>
                <div class="col-xs-12 mb-2" *ngFor="let item of item.progressReport;" style="max-height:200px;">
                  <div class="col-xs-12 mb-2"><span class="fontS2">{{item.timeline | date: 'd MMM yyyy' }}</span>
                    <button class="btn-xxs btn-secondary mx-4" (click)="openReport(item)">Open Progress</button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12 newUIHeader p-0" #newHeader *ngIf="showNewUI">
  <div class="hbox" #orderView fillComponentParentView="fillComponentParentView">
    <div class="flex-box" *ngIf="user && showNewUI && followUp">
      <div class="flex-heading">
        <div class="col-xs-12 list-header listHeader flex justifySB">
          <div class="pull-left bold-600 user-details flex">
            <a routerLink="/chat/{{user.id}}" target="_blank">
              <span class="text-capitalize fw-700">{{user.get('PatientName')}}, {{user.get('Age')}} {{user.get('Gender').substring(0, 1)}}&nbsp; | &nbsp;{{userCommunicationLanguage}}</span>
              <span class="text-capitalize fw-700" *ngIf="user.get('PrivateMainConcern')">&nbsp; | &nbsp;{{user.get('PrivateMainConcern')?.split('_').join(' ').toLowerCase()}}&nbsp;</span>
              <span class="text-capitalize fw-700" *ngIf="sensitiveSkin?.toLowerCase() === 'yes, its sensitive'">| Sensitive</span>
              <span class="text-capitalize fw-700" *ngIf="sensitiveSkin?.toLowerCase() === 'no, not sensitive'">| Not Sensitive</span>
            </a>
            <div class="flex flex-wrap">
              <span class="highlighed-text ml-5 fw-700" style="padding-left: 29px; padding-right: 29px;" *ngIf="pregnentMessages.includes(extraData.pregnancy)">Pregnant</span>
              <span class="highlighed-text ml-5 fw-700" style="padding-left: 29px; padding-right: 29px;" *ngIf="breastfeedingMessages.includes(extraData.pregnancy)">Breastfeeding</span>
              <span class="highlighed-text ml-5 fw-700" style="padding-left: 29px; padding-right: 29px;" *ngIf="extraData.pregnancy?.trim()?.split(' ').join('').toLowerCase() === 'iamplanningapregnancy'">Planning</span>
              <span class="highlighed-text ml-3 fw-700" style="padding-left: 29px; padding-right: 29px;" *ngIf="extraData.dissatisfiedUser">Dissatisfied</span>
              <span class="highlighed-text ml-3 fw-700" style="padding-left: 29px; padding-right: 29px;" *ngIf="user.get('marketingTags')?.includes('STEROID')">Steroid</span>
              <span class="highlighed-text ml-3 fw-700" style="padding-left: 29px; padding-right: 29px;" *ngIf="extraData.regimenChangeNeeded">Change Needed</span>
              <span class="highlighed-text ml-3 fw-700" style="padding-left: 29px; padding-right: 29px;" *ngIf="extraData.npdExperimentEnabled">NPD</span>
              <span class="highlighed-text ml-3 fw-700" style="padding-left: 29px; padding-right: 29px;" *ngIf="extraData.discontinuedProducts?.length">Dis Prod: {{extraData.discontinuedProducts.join(', ')}}</span>
            </div>
          </div>
          <div class="fontL2 text-cap pull-right flex min-100">
            <div class="regimen-class" *ngFor="let type of pendingReportTypes" (click)="onChangeSelectedFollowUpClass(type)"><span [ngClass]="{'selected-FollowUp-class': type == selectedFollowupClass}">{{type}}</span></div>
            <button class="done-finish-button btn-xs btn-primary-outline ml-3" *ngIf="followUp.get('State') === appConfig.Shared.FollowUp.State.PENDING && followUp.get('ready') && pendingReportTypes.length <= 1" [ngClass]="{'disabled-button': disableFinishAndSendButton}" (click)="finishAndSendReport()">Finish & Send</button>
            <button class="done-finish-button btn-xs btn-primary-outline ml-3" *ngIf="followUp.get('State') === appConfig.Shared.FollowUp.State.PENDING && followUp.get('ready') && pendingReportTypes.length > 1" [ngClass]="{'disabled-button': disableFinishAndSendButton}" (click)="finishAndSendReport()">Send Report</button>
            <button class="btn-xs btn-primary-outline ml-3" (click)="showNewUI = false">Old UI</button>
            <div class="posR pointer" (click)="callUserThroughExotel(user.get('MobileNumber'))" (dblclick)="callUserThroughExotel(user.get('alternateNumber'))"><em class="fa-solid fa-phone ml-3 call-icon" *ngIf="user.get('MobileNumber')" title="Mobile"></em><span class="call-icon-plus">+</span></div>
            <quick-action-buttons (sectionToScrollTo)="onChooseSection($event)" [quickActions]="quickActions"></quick-action-buttons>
          </div>
        </div>
      </div>
      <div class="flex-content" *ngIf="followUp">
        <section class="flex gap-12 w100">
          <div class="hbox section w40 chat-summary" id="chatSection">
            <div class="heading bg-white">
              <ng-container *ngIf="completedFollowUpOptions?.length">
                <input-select-text style="font-style: italic;" [options]="completedFollowUpOptions" [defaultSelect]="defaultSelectedFollowUp" name="completedFollowUpOptions" (onValueChanged)="onUpdateFollowUpOptions($event)" selectWidth="400px"></input-select-text>
              </ng-container>
              <div class="flex flex-dir-row"><span>chat view</span>
                <input type="checkbox" id="toggle" [(ngModel)]="selectChat" (ngModelChange)="changeSelectedChatType(selectChat)" name="selectedChatType"/>
                <label class="label" for="toggle"></label>
              </div>
            </div>
            <iframe class="hbox" #newChatView *ngIf="selectedChatType === 'userChat'" [src]="webAppUrl" style="height:800px; overflow-y: scroll; padding-bottom: 45px;"></iframe>
            <followup-chat-view-summary class="h100" *ngIf="selectedChatType === 'chatSummary'" [followupId]="selectedFollowupId" [user]="user"></followup-chat-view-summary>
          </div>
          <section class="section w60">
            <instant-checkup-magnifier [user]="user" (needHelpButtonClicked)="needHelpButtonClicked()"></instant-checkup-magnifier>
          </section>
        </section>
        <section class="section w100" id="regimenDetailsSection">
          <regimen-product-view [regimen]="selectedRegimen" [user]="this.user" (emitRegimen)="getRegimenDetails($event)"></regimen-product-view>
        </section>
        <section class="d-flex gap-12 w100 align-items-stretch">
          <div class="section w50">
            <doctor-notes [username]="user.get('username')" (newMedicalNoteAdded)="onNewMedicalNoteAdded($event)"></doctor-notes>
          </div>
          <div class="section w50">
            <support-ticket-list [user]="user"></support-ticket-list>
          </div>
        </section>
        <section class="create-report-heading"><span style="font-weight: 700;">Create checkup report &nbsp;</span><span>for user</span></section>
        <section class="w100" id="checkupSection">
          <followup-report-creation #FollowUpReportCreationComponent [user]="this.user" [followUp]="this.followUp" [class]="this.selectedFollowupClass" [regimen]="this.selectedRegimen" (createReport)="createReport($event)" (disableRegimenEdit)="disableRegimenEdit($event)" (changeVoiceNote)="changeVoiceNote($event)" (changeRegimen)="changeRegimen($event)"></followup-report-creation>
        </section>
        <section class="section w100" id="regimenSection" #scrollTarget *ngIf="selectedRegimen">
          <regimen-products-edit #RegimenProductsEditComponent [regimen]="selectedRegimen" (afterSaveRegimen)="afterSaveRegimen($event)" [npdExperimentEnabled]="extraData.npdExperimentEnabled" [medicalNoteAdded]="newMedicalNoteAdded"></regimen-products-edit>
        </section>
        <section class="section w100" *ngIf="selectedRegimen">
          <consent-form #consentForm [user]="user" [currentUser]="currentUser" [isIsotretConsent]="isIsotretConsent" [isFinasterideConsent]="isFinasterideConsent" (sendFormEmitter)="wantsToTriggerConsentForm($event)" [selectedRegimen]="selectedRegimen" (cancelFormEmitter)="wantsToCancelSendingConsentForm()"></consent-form>
        </section>
        <section class="section w100" id="voiceRecordingSection">
          <div class="flex col-xs-12" style="border-bottom: 1px solid #000; margin: 10px !important">
            <label class="text-capitalize fw-700" style="font-size: 22px;">Voice Note &nbsp;</label>
            <div class="voiceNoteUi" *ngIf="customVoiceNoteToggle"><span>auto</span>
              <label class="i-switch i-switch-sm bg-success ml-3">
                <input class="ml-3" type="checkbox" [(ngModel)]="disableVoiceNoteSOP" (click)="toggleVoiceNote()" name="disableVoiceNoteSOP"/><i></i>
              </label>
            </div>
          </div>
          <voice-recording #VoiceRecordingComponent [orderApprovalPage]="true"></voice-recording>
        </section>
      </div>
    </div>
  </div>
</div>
