
<mat-dialog-content class="mat-dialog-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Instant Check-ups Image</h4><i class="fas fa-times pull-right fontL3" (click)="dialogRef.close()"></i>
  </div>
  <div class="modal-body">
    <div class="flex flex-wrap gap-12 w-100">
      <div *ngFor="let instantCheckUp of instantCheckUpImageList; let index = index" [ngClass]="{'blur-photo': index == selectedInstantCheckUpImageIndex}" [ngStyle]="{ 'width': '130px' }"><img class="pointer" [src]="instantCheckUp.compressedImagePath || instantCheckUp.imagePath" width="130px" height="180px" (click)="selectImage(index)"/>
        <div class="col-xs-12 text-center fontR">{{instantCheckUp.createdAt | amDateFormat: 'DD MMM,YYYY'}}</div>
        <div class="col-xs-12 text-center fontR fw-700">({{instantCheckUp.differenceInDays}} days)</div>
        <div class="col-xs-12 text-center pointer fontS3 flex justifyC" (click)="editPhotoType(index, instantCheckUp.objectId)">
          <div class="text-cap">{{instantCheckUp.type}}</div><i class="fa fa-edit ml-2"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="pull-right" type="button" name="submit" (click)="closePopup()">Update</button>
  </div>
</mat-dialog-content>