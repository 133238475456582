
<div class="flex alignFS">
  <div>
    <label class="mt-0">Pick a file</label>
    <label class="upload" [for]="id" #filePicker=""><em class="fas fa-plus" *ngIf="!imageSrc && !imageSrc"></em><img #imageElement="" *ngIf="imageSrc" [src]="imageSrc" (load)="isValidAspectRatio(imageElement)"/>
      <video #videoElement="" *ngIf="videoSrc" [src]="videoSrc" controls="controls" crossOrigin="Anonymous" (canplaythrough)="isValidAspectRatio(videoElement)"></video><i class="fas fa-trash" *ngIf="imageSrc || videoSrc" (click)="removeFile()"></i>
      <input type="file" name="upload" [id]="id" [accept]="accept" (change)="onFileUpload($event)"/>
    </label>
  </div>
  <div class="video-thumbnail" *ngIf="videoSrc">
    <label class="mt-0">Pick a thumbnail</label>
    <div class="pb-3">Pause the video to pick that frame as thumbnail for the video & click "Capture"</div>
    <div>
      <button class="btn-primary btn-xs mr-3" (click)="captureThumbnail(videoElement)" type="button">Capture</button>
      <button class="btn-secondary btn-xs" *ngIf="thumbnailSrc" (click)="uploadThumbnail()" type="button">Confirm</button>
    </div><img class="thumb bdr" *ngIf="thumbnailSrc" [src]="thumbnailSrc"/>
  </div>
</div>
