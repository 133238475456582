
<div class="flex-box" fillComponentParentView="fillComponentParentView">
  <div class="flex-heading">
    <div class="list-header col-xs-12">
      <div class="pull-left"><span class="pR25">Reminders</span></div>
      <div class="pull-right"><i class="fa fa-plus-square-o mr-3 pointer" (click)="openForm()"></i></div>
    </div>
  </div>
  <div class="flex-content scroll-x">
    <div class="list-view">
      <div class="list-sidebar">
        <div class="header">
          <div class="title">Filters</div>
          <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
        </div>
        <div class="content p-0"></div>
      </div>
      <div class="list-content">
        <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
<ng-template #confirmDelete="">
  <div class="dialog-container">
    <h2 mat-dialog-title="mat-dialog-title">Are you sure you want to delete reminder?</h2>
    <mat-dialog-actions align="end">
      <button class="mr-3 small btn-secondary bold" [mat-dialog-close]="false"> Close</button>
      <button class="small btn-danger bold" [mat-dialog-close]="true"> Delete</button>
    </mat-dialog-actions>
  </div>
</ng-template>