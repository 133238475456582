
<div class="hbox bg-white-only" fillComponentParentView="fillComponentParentView">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="list-header col-xs-12"><span class="pr-3">FollowUp ({{count}})</span></div>
    </div>
    <div class="flex-content scroll-x">
      <div class="list-view">
        <div class="list-sidebar">
          <div class="header">
            <div class="title">Filters</div>
            <button class="btn-xxs btn-secondary" (click)="resetFilters()">Reset</button>
          </div>
          <div class="content p-0">
            <div class="flex mb-3 px-3" style="flex-direction: column; align-items: flex-start;">
              <div class="flex">
                <label class="mr-3">Auto Refresh</label>
                <label class="i-switch i-switch-sm bg-success">
                  <input type="checkbox" [(ngModel)]="autoRefresh" (ngModelChange)="toggleAutoRefresh()" name="autoRefresh"/><i></i>
                </label>
              </div>
              <div class="flex" *ngIf="userService.includes('admin') || userService.includes('adminDoctor')">
                <label class="mr-3">Admin Toggle</label>
                <label class="i-switch i-switch-sm bg-success">
                  <input type="checkbox" [(ngModel)]="adminToggle" (click)="toggleAdminStatus()" name="adminToggle"/><i></i>
                </label>
              </div>
            </div>
            <div class="flex mb-3 px-3 bdr-top pt-3" *ngIf="this.changingFollowupDoctorList.length">
              <button class="btn-xxs btn-secondary" (click)="openAllocatedDoctorDialog()">Edit Allocation</button>
              <button class="btn-xxs btn-secondary ml-3" (click)="clearAllSelectedFollowUp()">Clear</button>
            </div>
            <div class="px-3 bdr-top">
              <collapse [title]="this.appConfig.Shared.ListSideBar.Types.INTERNAL_TEAMS" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.DOCTOR" [showList]="true">
                <internal-team-filter (updatedInternalTeamList)="updatedInternalTeamList($event)" [reset]="resetInternalTeamFilter" [type]="'DERMAT_TEAM'"></internal-team-filter>
              </collapse>
            </div>
            <div class="px-3 bdr-top">
              <collapse title="Type" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.DOCTOR" [showList]="true">
                <button class="btn-xs bdr-rad-15 mr-2 mb-2 text-lwr" (click)="toggleSelection('dermatologist')" [ngClass]="{'btn-primary': type == 'dermatologist', 'btn-primary-outline': type != 'dermatologist' }">dermatologist</button>
                <button class="btn-xs bdr-rad-15 mr-2 mb-2 text-lwr" (click)="toggleSelection('mbbs')" [ngClass]="{'btn-primary': type == 'mbbs', 'btn-primary-outline': type != 'mbbs' }">mbbs</button>
              </collapse>
            </div>
            <div class="px-3 bdr-top">
              <collapse [title]="this.appConfig.Shared.ListSideBar.Types.LANGUAGES" [iconClass]="this.appConfig.Shared.ListSideBar.Icons.LANGUAGES" [showList]="true">
                <language-filter (updatedLanguageList)="updateLanguageFilter($event)" [selectDefaultLanguage]="true"></language-filter>
              </collapse>
            </div>
            <section class="mt-3 px-3 flex list-color-holder"><span class="list-colors-circle mr-2 bg-danger" data-tooltip-text="Tablet or Antibiotic" data-tooltip-pos="right"></span><span class="list-colors-circle mr-2 bg-pink" data-tooltip-text="Doctor Call needed" data-tooltip-pos="right"></span></section>
          </div>
        </div>
        <div class="list-content">
          <ag-grid-angular class="ag-theme-balham" style="height: 100%;" [columnDefs]="columnDefs" [components]="components" [rowBuffer]="0" [rowModelType]="ui.grid.rowModelType" [paginationPageSize]="ui.grid.pageSize" [cacheBlockSize]="ui.grid.pageSize" [cacheOverflowSize]="0" [maxConcurrentDatasourceRequests]="1" [infiniteInitialRowCount]="1" [maxBlocksInCache]="10" (gridReady)="onGridReady($event)"></ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>